import React, { useEffect, useState } from "react"
import { Transition } from "@headlessui/react"
import BlockContent from "@sanity/block-content-to-react"

import Spell from "../../../types/Spell"

const PassiveSpell: React.FC<{ spell: Spell; index: number }> = ({
  spell,
  index,
}) => {
  let text = []

  // console.log("passive spell", spell)
  const [content, setContent] = useState("")

  useEffect(() => {
    {
      spell._rawEffect[0]?.children.map(value => {
        const styles =
          spell._rawEffect[0]?.children.slice(-1)[0].text === "."
            ? `margin-left:-3px`
            : null
        const classValue =
          value.marks.length > 0
            ? "mt-1 text-sm md:text-base underline cursor-pointer text-indigo-600 hover:text-indigo-600"
            : "mt-1 text-sm md:text-base text-gray-900"
        const res =
          value.marks.length > 0
            ? `<a class="${classValue}" href=${spell._rawEffect[0]?.markDefs[0]?.href}>${value.text}</a>`
            : `<span class="${classValue}" style=${styles}>${value.text}</span>`
        text.push(res)
      })
    }
    const result = text.join(" ")
    setContent(result)
    // console.log(result, "text")
  }, [])

  return (
    <>
      <td
        colSpan={6}
        className="table-cell w-full px-7 py-4 pb-8 whitespace-nowrap"
      >
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-2 sm:col-span-1">
            <dt className="text-sm md:text-base font-medium text-gray-500 wc">
              Requirements
            </dt>
            <dd className="mt-1 text-sm md:text-base text-gray-900 wc">
              {spell.requirements}
            </dd>
          </div>
          <div className="col-span-2 sm:col-span-2">
            <dt className="text-sm md:text-base font-medium text-gray-500 wc">
              Effect
            </dt>
            <dd
              style={{ color: "white" }}
              className="prose max-w-none"
              // dangerouslySetInnerHTML={{ __html: content }}
            >
              <BlockContent blocks={spell._rawEffect} />
            </dd>
          </div>
        </div>
      </td>
    </>
  )
}

export default PassiveSpell
