import React from "react"
import ActiveSpell from "./active-spell"
import PassiveSpell from "./passive-spell"
import { Transition } from "@headlessui/react"

const SpellsSection = ({ spells }) => {
  const [expanded, setExpanded] = React.useState(null)

  const closeAll = index => {
    if (index == expanded) {
      setExpanded(null)
    } else {
      setExpanded(index)
    }
  }

  // console.log("spells", spells)

  return (
    <div className="mt-3 flex flex-col">
      <div className="mt-3 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 bcg">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider wc"
                    style={{ textAlign: "center" }}
                  >
                    Level
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider wc"
                    style={{ textAlign: "center" }}
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="hidden md:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider wc"
                    style={{ textAlign: "center" }}
                  >
                    Summary
                  </th>
                  <th
                    scope="col"
                    className="hidden md:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider wc"
                    style={{ textAlign: "center" }}
                  >
                    Type
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">More</span>
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white divide-y divide-gray-200 bcg">
                {spells.map((spell, index) => {
                  return (
                    <>
                      <tr
                        onClick={() => closeAll(index)}
                        className="even-bgc   cursor-pointer bcg"
                      >
                        <td className="px-6  py-2 whitespace-nowrap text-sm md:text-base font-medium text-gray-500 wc">
                          {spell.level}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm md:text-base font-medium text-gray-900 wc">
                          {spell.name}
                        </td>
                        <td className="hidden md:table-cell px-6 py-4 whitespace-nowrap text-sm md:text-base text-gray-500 wc">
                          {/* {spell._rawFlavorText == null
                            ? ""
                            : spell._rawFlavorText[0].children[0].text} */}
                          {/* {spell?._rawEffect[0].children[0].text} */}
                          {spell?.spell_flavor_text}
                        </td>
                        <td className="hidden md:table-cell px-6 py-4 whitespace-nowrap text-sm md:text-base text-gray-500 wc">
                          {spell.type}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm md:text-base font-medium">
                          <button
                            type="button"
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            More
                          </button>
                        </td>
                      </tr>
                      <Transition
                        as="tr"
                        show={expanded == index ? true : false}
                        className="shadow-inner border-gray-200"
                      >
                        {spell.type == "Active" ? (
                          <ActiveSpell spell={spell} index={index} />
                        ) : (
                          <PassiveSpell spell={spell} index={index} />
                        )}
                      </Transition>
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpellsSection
