import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-plugin-sanity-image"
import BlockContent from "@sanity/block-content-to-react"
import HeroSpell from "../types/HeroSpell"
import Layout from "../components/layout"
import SpellsSection from "./classes/components/spells"

interface DataProps {
  data: {
    heroSpell: HeroSpell
  }
}

const HeroSpells = ({ data }) => {
  const heroSpell = data.allSanityHerospells.nodes

  // console.log(data, "heroSpells")

  return (
    <Layout>
      <div className="bg-texture block">
        <div className="max-w-4xl mx-auto px-6 sm:px-0 bg-title">
          <div className="mt-12">
            <h1 className="text-center text-mocha text-5xl font-display font-bold">
              {heroSpell[0].name}
            </h1>
          </div>
          {/* <div className="w-80 sm:float-right"> */}
          <div className="mt-5 mb-5">
            <Image
              {...heroSpell[0].image}
              alt={`${heroSpell[0].name} Avatar`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>

          {/* <div className="max-w-none font-body text-fontSize  mx-auto prose lg:prose-lg font-medium"> */}
          <div className="font-body text-fontSize body max-w-none mx-auto prose leading-relaxed font-medium">
            <BlockContent blocks={heroSpell[0]._rawFlavorText} />
          </div>

          <div className="mt-12">
            <h3 className="text-mocha text-4xl font-display font-bold text-center">
              Arcana
            </h3>
            <SpellsSection spells={heroSpell[0].arcanaspells} />
          </div>
          <div className="mt-12">
            <h3 className="text-mocha text-4xl font-display font-bold text-center">
              Combat
            </h3>
            <SpellsSection spells={heroSpell[0].combatspells} />
          </div>
          <div className="mt-12">
            <h3 className="text-mocha text-4xl font-display font-bold text-center">
              Ranged
            </h3>
            <SpellsSection spells={heroSpell[0].rangedspells} />
          </div>
          <div className="mt-12">
            <h3 className="text-mocha text-4xl font-display font-bold text-center">
              Skill
            </h3>
            <SpellsSection spells={heroSpell[0].skillspells} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HeroSpells

export const query = graphql`
  query MyQuery {
    allSanityHerospells(filter: { name: { eq: "Hero Spells" } }) {
      nodes {
        id
        name
        _rawFlavorText
        arcanaspells {
          name
          level
          type
          roll_cost
          mana_cost
          action
          range
          non_battle_mode
          duration
          handicaps
          requirements
          _rawEffect
          spell_flavor_text
        }
        combatspells {
          name
          level
          type
          roll_cost
          mana_cost
          action
          range
          non_battle_mode
          duration
          handicaps
          requirements
          _rawEffect
          spell_flavor_text
        }
        rangedspells {
          name
          level
          type
          roll_cost
          mana_cost
          action
          range
          non_battle_mode
          duration
          handicaps
          requirements
          _rawEffect
          spell_flavor_text
        }
        skillspells {
          name
          level
          type
          roll_cost
          mana_cost
          action
          range
          non_battle_mode
          duration
          handicaps
          requirements
          _rawEffect
          spell_flavor_text
        }
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`
